export default {
  watch: {
    '$store.state.user.contractDetails': {
      async handler(customerContracts) {
        if (customerContracts) {
          const contractTypes = [];
          contractTypes.push(customerContracts.productOfferCode);

          await this.$store.dispatch('device/GET_DEVICE_TYPES', contractTypes);
        }
      },
      immediate: true,
    },
  },
};
