<template>
  <hs-main class="add-device">
    <!-- save changes popup BEGIN -->
    <hs-popup
      v-if="showSavePopup"
      aria-labelledby="save-modal-title"
      aria-describedby="save-modal-desc"
      confirm-button-text="Continue Adding Device"
      decline-button-text="Discard data"
      @hide="closeSaveDialog"
      @confirm="closeSaveDialog"
      @decline="discardChanges"
    >
      <h1 class="hs-popup__title" id="save-modal-title">
        You’re almost done. Are you sure you want to leave?
      </h1>
      <div class="hs-popup__description">
        <p id="save-modal-desc">
          You’re just one step away from adding your device to the plan. Are you sure you want to
          leave this page and discard all device information?
        </p>
      </div>
    </hs-popup>
    <!-- save changes popup END -->
    <!-- pending device popup BEGIN -->
    <hs-popup
      v-if="pendingDevicePopup"
      aria-labelledby="pending-device-modal-title"
      aria-describedby="pending-device-modal-desc"
      confirm-button-text="Ok"
      @hide="goToMyDevices"
      @confirm="goToMyDevices"
    >
      <h1 class="hs-popup__title" id="pending-device-modal-titile">
        Your device registration is pending
      </h1>
      <div class="hs-popup__description">
        <p id="pending-device-modal-desc">
          We need to validate your device. Please allow up to 1 business day after your request to
          receive validation notification.
        </p>
      </div>
    </hs-popup>
    <!-- pending device popup END -->
    <div class="add-device__row">
      <div class="add-device__col add-device__header">
        <hs-heading>Add a New Device</hs-heading>
        <router-link
          class="add-device__close"
          :to="{
            name: 'MyDevices',
            query: $route.query,
          }"
        >
          Close
        </router-link>
      </div>
    </div>
    <form @submit.stop.prevent="finish" class="add-device__fieldset">
      <div class="add-device__row">
        <div class="add-device__col add-device__col--6">
          <hs-label for="type" class="add-device__label">
            1.Choose what type of device you want to add
          </hs-label>
          <hs-select
            :options="types"
            :isDisabled="disableTypesSelect"
            :value="type"
            id="type"
            placeholder="Select device type from drop down list below"
            @input="selectType"
          />
          <hs-label for="brand" class="add-device__label">
            2.Select the brand of device you wish to add
          </hs-label>

          <hs-select
            :options="brands"
            :isDisabled="disableBrandsSelect"
            :value="brand"
            :emptyItem="{value: 'other', text: 'Other (brand not listed)'}"
            id="brand"
            placeholder="Select device brand from drop down list below"
            @input="selectBrand"
          />
          <hs-input
            v-if="showOtherBrandInput"
            :value="otherBrand"
            ref="otherBrand"
            id="other-brand"
            placeholder="Please enter your device brand name"
            class="add-device__other-info-input"
            @input="SET_OTHER_BRAND"
          />
          <hs-label for="search-field" class="add-device__label">
            {{ modelLabelText }}
          </hs-label>
          <hs-select
            v-if="showModelSelect"
            :options="models"
            :value="model"
            :isDisabled="disableModelsSelect"
            :emptyItem="{value: 'other', text: 'Other (model not listed)'}"
            id="search-field"
            placeholder="Select device model from drop down list below"
            @input="selectModel"
          />
          <hs-input
            v-if="showOtherModelInput"
            :value="otherModel"
            ref="otherModel"
            id="other-model"
            placeholder="Enter the model name of your device"
            class="add-device__other-info-input"
            @input="SET_OTHER_MODEL"
          />
        </div>
        <div class="add-device__col add-device__col--6">
          <hs-label for="device-serial-number" class="add-device__label">
            4. Add device serial number
          </hs-label>
          <p class="add-device__field-explanation">
            Device serial number is required when requesting service
          </p>
          <hs-input
            :value="sn"
            :error="snError"
            ref="deviceSN"
            id="device-serial-number"
            placeholder="xxxxxxxxxxxxx"
            @input="onSerialNumberChange"
          />
          <div :class="{'add-device__hint--with-guide': guideOpened}" class="add-device__hint">
            <div v-if="guideOpened" class="add-device__sn-guide-cont">
              <SNGuide @close="guideOpened = false" />
            </div>
            <div class="add-device__mobile-close-guide-btn">
              <hs-button type="button" @click="guideOpened = !guideOpened">Ok, got it!</hs-button>
            </div>
            <button
              type="button"
              class="hs-link add-device__hint-btn"
              @click="guideOpened = !guideOpened"
            >
              Short guide tip:
            </button>
            where to find your device serial number
          </div>
          <hs-label for="device-nickname" class="add-device__label">
            5. Create a nickname for your device (optional)
          </hs-label>
          <hs-input
            :error="nicknameError"
            :value="nickname"
            ref="deviceNickName"
            id="device-nickname"
            placeholder="My device’s nickname"
            @input="SET_NICKNAME"
          />
        </div>
      </div>
      <StepNavigationButtons
        :disabled="!enableSaveDevice || saveDeviceInProgress"
        :has-back-button="false"
        confirm-button-text="Add Device"
      />
    </form>
  </hs-main>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import '@/assets/styles/device.scss';
import SNGuide from '@/components/device/add/SerialNumberGuide';
import StepNavigationButtons from '@/components/shared/StepNavigationButtons';
import fetchDeviceTypes from '@/mixins/fetchDeviceTypes';

export default {
  metaInfo: {
    titleTemplate: 'Add Device - %s',
  },
  components: {
    SNGuide,
    StepNavigationButtons,
  },
  mixins: [fetchDeviceTypes],
  data() {
    return {
      to: null,
      dirty: false,
      showSavePopup: false,
      forceNavigation: false,
      guideOpened: false,
      pendingDevicePopup: false,
      nicknameError: '',
      snError: '',
      saveDeviceInProgress: false,
    };
  },
  computed: {
    ...mapGetters('myDevices', ['activeDevices']),
    ...mapGetters('device', ['types', 'brands', 'models']),
    ...mapState('device', ['type', 'brand', 'model', 'nickname', 'sn', 'otherBrand', 'otherModel']),
    enableSaveDevice() {
      return (
        (this.showOtherBrandInput
          ? this.otherBrand.trim() && this.otherModel.trim()
          : this.showOtherModelInput
          ? this.otherModel.trim()
          : this.model) && this.sn.trim() !== ''
      );
    },
    disableTypesSelect() {
      return !this.types?.length;
    },
    disableBrandsSelect() {
      return !this.type || !this.brands?.filter((i) => i?.value !== 'other')?.length;
    },
    disableModelsSelect() {
      return !this.brand || !this.models?.filter((i) => i?.value !== 'other')?.length;
    },
    showOtherBrandInput() {
      return this.brand === 'other';
    },
    showModelSelect() {
      return this.brand !== 'other';
    },
    showOtherModelInput() {
      return this.showOtherBrandInput || this.model === 'other';
    },
    modelLabelText() {
      return this.showModelSelect
        ? '3.Choose the model of your device'
        : '3.Please specify the model of your device';
    },
  },
  methods: {
    ...mapMutations('device', [
      'SET_DEVICE_BRANDS',
      'SET_DEVICE_MODELS',
      'SET_DEVICE_TYPE',
      'SET_DEVICE_BRAND',
      'SET_OTHER_BRAND',
      'SET_DEVICE_MODEL',
      'SET_OTHER_MODEL',
      'SET_NICKNAME',
      'SET_SN',
      'CLEAR_STORE',
      'SET_SAVE_DEVICE_IN_PROGRESS',
    ]),
    ...mapActions('device', [
      'GET_DEVICE_TYPES',
      'GET_DEVICE_BRANDS',
      'GET_DEVICE_MODELS',
      'SAVE_DEVICE',
    ]),
    async discardChanges() {
      const {query} = this.$route;
      this.forceNavigation = true;
      this.showSavePopup = false;
      this.$router.push({
        name: 'MyDevices',
        query,
      });
    },
    async saveChanges() {
      this.showSavePopup = false;
      this.forceNavigation = true;
      await this.$router.push(this.to);
    },
    isNameUnique(name) {
      if (!name?.trim()) return true;
      if (this.activeDevices?.length) {
        const clearName = name?.trim()?.toLowerCase();
        if (!clearName) return true;
        for (const d of this.activeDevices) {
          if (d.equipmentName) {
            const clearNickname = d.equipmentName?.trim()?.toLowerCase();
            if (clearNickname === clearName) return false;
          }
        }
      }
      return true;
    },
    closeSaveDialog() {
      this.showSavePopup = false;
    },
    setPopupHandler() {
      window.onbeforeunload = this.beforeunloadHandler;
    },
    selectType(type) {
      this.SET_DEVICE_BRANDS([]);
      this.SET_DEVICE_MODELS([]);
      this.GET_DEVICE_BRANDS(type);
      this.SET_DEVICE_TYPE(type);
      this.selectBrand('');
      this.dirty = true;
      this.setPopupHandler();
    },
    selectBrand(brand) {
      this.SET_DEVICE_MODELS([]);
      const {type} = this;
      if (brand && brand !== 'other') this.GET_DEVICE_MODELS({type, brand});
      this.SET_DEVICE_BRAND(brand);
      this.SET_OTHER_BRAND('');
      this.selectModel('');
      this.dirty = true;
      this.setPopupHandler();
    },
    selectModel(model) {
      this.SET_DEVICE_MODEL(model);
      this.SET_OTHER_MODEL('');
      this.dirty = true;
      this.setPopupHandler();
    },
    onSerialNumberChange(value) {
      this.SET_SN(value);
      this.dirty = value.trim().length > 0;
      this.setPopupHandler();
    },
    finish() {
      this.nicknameError = '';
      this.snError = '';
      if (!this.enableSaveDevice) {
        return;
      }

      if (!this.isNameUnique(this.nickname)) {
        this.nicknameError = 'Nickname should be unique to your device';
        return;
      }
      this.saveDeviceInProgress = true;
      const {customerRef} = this.$route.query;
      this.SAVE_DEVICE({customerRef})
        .then(() => {
          this.dirty = false;
          if (this.otherBrand || this.otherModel) {
            this.pendingDevicePopup = true;
          } else {
            this.goToMyDevices();
          }
        })
        .catch((e) => {
          if (e?.toLowerCase().indexOf('serial number') !== -1) {
            this.snError = e;
            this.saveDeviceInProgress = false;
          }
        });
    },
    goToMyDevices() {
      const {query} = this.$route;
      this.$router.push({
        name: 'MyDevices',
        query,
      });
    },
    beforeunloadHandler(e) {
      e.preventDefault();
      e.returnValue = '';
    },
  },
  beforeCreate() {
    const {customerRef} = this.$route.query;
    this.$store
      .dispatch('user/DECRYPT_PARAMS', {
        customerRef,
      })
      .then((resp) => {
        this.$store.dispatch('myDevices/GET_MY_DEVICES', {
          customerRef: resp?.customerRef,
        });
      });
  },
  beforeMount() {
    this.SET_DEVICE_TYPE('');
    this.SET_DEVICE_BRAND('');
    this.SET_DEVICE_MODEL('');
  },
  beforeDestroy() {
    window.onbeforeunload = null;
    this.CLEAR_STORE();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.dirty || this.forceNavigation) {
      next();
    } else {
      this.to = to;
      this.showSavePopup = true;
    }
  },
};
</script>

<style lang="scss">
$colPadding: 0 50px;
.add-device {
  padding-bottom: 120px;

  @include mf($gridMDBreakpoint) {
    padding-bottom: 30px;
  }
  &__button .hs-btn {
    width: 100%;
  }
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__fieldset {
    margin: 20px 0 0;
  }
  &__row {
    @include mf($gridMDBreakpoint) {
      display: flex;
      // margin: 35px -45px 0;
    }
  }
  &__col {
    &:nth-child(even) {
      padding: 35px 0;

      @include mf($gridMDBreakpoint) {
        padding: $colPadding;
      }
    }

    @include mf($gridMDBreakpoint) {
      flex: 1;
      padding: $colPadding;
      &--6 {
        flex: 1 0 50%;
        max-width: 50%;
      }
    }
  }
  &__field-explanation {
    margin: 0 0 8px;
  }
  &__other-info-input {
    margin-top: 12px;
  }
  &__label {
    margin-top: 36px;
    &:first-child {
      margin-top: 0;
    }
  }
  &__search-input {
    margin-top: 16px;
  }
  &__hint {
    position: relative;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
    font-size: 14px;
    line-height: 120%;
    background: rgba(#007bc7, 0.05);
    border-radius: 4px;

    @include mf($gridMDBreakpoint) {
      display: flex;
      width: fit-content;
    }
    &--with-guide::after {
      position: absolute;
      top: 50%;
      left: -10px;
      display: block;
      width: 0;
      height: 0;
      border-top: 20px outset transparent;
      border-bottom: 20px outset transparent;
      border-left: 20px solid $white;
      transform: translateY(-50%);
      content: '';
    }
    .add-device__hint-btn {
      position: relative;
      display: inline-flex;
      margin-right: 5px;
      padding-left: 20px;
      color: #007bc7;
      font-size: inherit;
      text-decoration: none;
      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 16px;
        background: url(~@/assets/images/question.svg) no-repeat 0 50%/16px 16px;
        content: '';
      }
      a {
        margin-right: 5px;
        color: #007bc7;
        font-size: inherit;
        text-decoration: none;
      }
    }
  }
  &__close {
    display: block;
    width: 12px;
    height: 12px;
    margin-top: 11px;
    margin-left: auto;
    overflow: hidden;
    text-indent: -9999px;
    background: url(~@/assets/images/add-device-close.svg) no-repeat 0 0/12px 12px;

    @include mf($gridMDBreakpoint) {
      margin-top: 16px;
    }
  }
  &__sn-guide-cont {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 10px 100px;
    overflow: auto;
    background-color: $white;
    transform: translateY(-100%);

    @include mf($gridMDBreakpoint) {
      position: absolute;
      top: 50%;
      right: calc(100% + 5px);
      bottom: auto;
      left: auto;
      display: none;
      width: calc(100vw - 20px);
      max-width: 525px;
      max-height: calc(100vh - 50px);
      padding: 30px;
      border-radius: 4px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    }
  }
  &__hint--with-guide &__sn-guide-cont {
    z-index: 10;
    transform: translateY(0);

    @include mf($gridMDBreakpoint) {
      z-index: 0;
      display: block;
      transform: translateY(-50%);
    }
  }
  &__mobile-close-guide-btn {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    display: none;
    flex-direction: column;
    padding: 20px 10px;
    background: linear-gradient(to top, $white, rgba($white, 0.4));
  }
  &__hint--with-guide &__mobile-close-guide-btn {
    display: flex;

    @include mf($gridMDBreakpoint) {
      display: none;
    }
  }
  &__footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 23px 0;
    background-color: $white;
    box-shadow: -2px 0 16px rgba(0, 0, 0, 0.25);

    @include mf($gridMDBreakpoint) {
      position: static;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 37px;
      border-top: 1px solid $gray;
      box-shadow: none;
    }
  }
}
</style>
