export default [
  {
    id: 'apple',
    title: 'For iOS users',
    text: 'Go to "Settings". Then find "General" and tap "About". You can also find your serial number in "Finder", iTunes, or on the original packaging.',
  },
  {
    id: 'samsung',
    title: 'For Android (Samsung users)',
    text: 'Navigate to and "Open Settings" and then tap "About Phone" or "About Tablet".',
  },
  {
    id: 'lg',
    title: 'For Google Pixel users',
    text: [
      'Select the Settings icon.',
      'Scroll down to select "About Phone." The device phone number will be displayed.',
      'On the "About Phone" screen, select "Model & Hardware." The device Serial Number will be displayed.',
    ],
  },
  {
    id: 'google',
    title: 'Quick Tip for Samsung or Google users',
    text: 'Dial *#06# on the phone to display the IMEI and serial number.     (Note: This only works when supported by your mobile carrier).',
  },
];
